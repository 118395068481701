.footer {
  position: relative;
  padding: 20px var(--padding);
  z-index: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: #fff;
  font-size: var(--font-regular);
  font-family: "Source Sans 3";
}

.leftContent {
  padding-left: 20px;
}
.rightContent {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  gap: 5px;
  text-align: center;
}

.icon {
  margin-left: 15px;
  font-size: var(--font-regular);
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #aaa;
  }
}
/* Tablet - Landscape */
@media screen and (max-width: 1024px) {
}

/* Tablet - Portrait */
@media screen and (max-width: 768px) {
  .footer {
    padding: 10px var(--padding-mobile);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .leftContent,
  .rightContent {
    padding: 0;
    text-align: center;
    flex: 1;
  }
}

/* Mobile */
@media screen and (max-width: 480px) {
}
