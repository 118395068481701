@import url("./global.css");

/* Nav Container */
.navContainer {
  position: relative;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 10;
}

.navContainer a {
  text-decoration: none;
  color: black;
}

/* Nav Content Wrapper */
.nav {
  position: relative;
}

.navContent {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between logo and links */
  padding: 0 20px;
}

/* Logo Styles (unchanged) */
.logo {
  font-size: var(--font-xxlarge);
  font-family: "Voga";
}

.logo .text {
  color: var(--logo-color);
  font-weight: bold;
  letter-spacing: 0.5rem;
}

.logoContainer {
  position: relative;
  margin-right: calc(var(--font-xxlarge) * 0.6);
}

.logo img {
  width: calc(var(--font-xxlarge) * 0.6);
  left: calc(var(--font-xxlarge) * -0.1);
  position: absolute;
  bottom: 5px;
}

/* Links */
.links {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.links ul {
  list-style-type: none;
  display: flex;
  gap: 20px; /* Space between list items */
  margin: 0;
  padding: 0;
}

.links ul li {
  font-size: var(--font-xlarge);
  font-family: "Fjalla One";
  padding: 20px 10px;
}

.links ul li:hover {
  background-color: rgb(233, 233, 233);
}

/* Menu Icon */
.menuIcon {
  font-size: var(--font-xlarge);
  display: none; /* Hidden in desktop view */
}

/* Responsive Adjustments */
@media screen and (max-width: 1024px) {
  .navContent {
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }

  .menuIcon {
    display: inline-block;
    align-items: center;
    position: relative;
    z-index: 15;
  }

  .links {
    display: none; /* Hidden in mobile view by default */
  }

  .linksActive {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    z-index: 10;
  }

  .linksActive ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: right;
  }

  .linksActive ul li {
    font-size: var(--font-large);
    padding: 10px 20px;
    cursor: pointer;
  }

  .linksActive ul li:hover {
    background-color: rgb(233, 233, 233);
  }
}
