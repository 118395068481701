.emailForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 30px;
}

.emailForm form {
  width: 1000px;
  background-color: white;
  padding: 30px 40px; /* Padding for inner content */
}
.title {
  width: 100%;
  text-align: center;
  font-size: var(--font-xxlarge);
  font-family: "Fjalla One";
  margin-bottom: 40px; /* Adjusted spacing */
}

.inputRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px; /* Added consistent gap */
  margin-bottom: 20px;
}

.inputText {
  flex: 1 1 100%;
}

.inputDateTime {
  flex: 1 1 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.inputDate,
.inputTime {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: var(--font-regular);
  width: 50%;
}

.inputText input,
.inputText label {
  width: 100%;
}

.inputText input {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: var(--font-regular);
  margin-top: 5px;
}

.inputText label {
  font-size: var(--font-regular);
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

.inputText input:focus,
.inputDate:focus,
.inputTime:focus {
  border-color: #daa520;
  box-shadow: 0px 0px 5px #daa520;
  outline: none;
}

/* Submit Button */
.submitBtn {
  display: flex;
  justify-content: flex-end;
}

.submitBtn button {
  padding: 10px 30px;
  background-color: #daa520;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: var(--font-medium);
  font-weight: bold;
  transition: all 0.3s ease;
}

.submitBtn button:hover {
  background-color: #bf8f17;
  transform: scale(1.05);
  cursor: pointer;
}

/* Error Highlighting */
.error {
  border-color: red;
  box-shadow: 0 0 5px red;
}

/* Tablet - Portrait */
@media screen and (max-width: 768px) {
  .emailForm form {
    width: 400px;
    padding: 20px 30px; /* Compact padding for smaller devices */
  }

  .title {
    font-size: var(--font-xlarge);
    margin-bottom: 20px;
  }

  .inputRow {
    gap: 10px;
  }

  .inputDate,
  .inputTime {
    width: 100%; /* Full-width inputs for smaller screens */
  }

  .submitBtn button {
    font-size: var(--font-regular);
    padding: 10px 20px;
  }
}

/* Mobile */
@media screen and (max-width: 480px) {
  .emailForm {
    padding: 15px 20px;
  }

  .title {
    font-size: var(--font-medium);
    margin-bottom: 15px;
  }

  .inputDate,
  .inputTime {
    width: 100%;
  }
}
