.container {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding);
  margin-bottom: 100px;
  width: 100%;
  max-width: 1200px; /* Limit the max-width to 1200px */
  margin: 0 auto; /* Center the content horizontally */
}

.title {
  margin: 100px 0 50px 0;
  font-family: "Fjalla One";
  font-size: var(--font-xxlarge);
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap; /* Allow content to wrap on smaller screens */
}

.image,
.textContent {
  width: 48%; /* Adjust to fit side by side on larger screens */
}

.image img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  object-fit: cover; /* Ensures images cover the container properly */
}

.textContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Source Sans 3";
  text-align: left; /* Align text to the left for better readability */
}

.textContent .subTitle {
  margin-bottom: 20px;
  font-size: var(--font-xlarge);
  font-weight: 200;
  text-align: center;
}

.textContent .text {
  font-size: var(--font-medium);
  text-align: center;
}

/* Tablet - Landscape */
@media screen and (max-width: 1024px) {
  .container {
    padding: 0 var(--padding-mobile);
  }
  .row {
    flex-direction: column; /* Stack the content on smaller screens */
    gap: 30px;
  }

  .image,
  .textContent {
    width: 100%; /* Make images and text content full width on tablets */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .textContent .subTitle {
    font-size: var(--font-large);
  }

  .textContent .text {
    width: 75%;
    font-size: var(--font-regular);
  }
}

/* Tablet - Portrait */
@media screen and (max-width: 768px) {
  .container {
    padding: 0 var(--padding-mobile);
  }
  .title {
    margin: 20px 0 20px 0;
    font-size: var(--font-xlarge);
  }
  .row {
    flex-direction: column;
    gap: 30px;
  }

  .textContent .subTitle {
    font-size: var(--font-medium);
    margin-bottom: 10px;
  }

  .textContent .text {
    font-size: var(--font-small);
  }
}

/* Mobile */
@media screen and (max-width: 480px) {
  .container {
    padding: 0 var(--padding-mobile);
  }

  /* Make the row stack vertically */
  .row {
    flex-direction: column;
    align-items: center; /* Center the content */
    gap: 20px; /* Add space between text and image */
  }

  .image,
  .textContent {
    width: 100%; /* Ensure both the image and text content are full width */
  }

  .textContent .subTitle {
    font-size: var(--font-medium); /* Adjust subtitle size */
    text-align: center; /* Center the subtitle text */
    margin-bottom: 10px;
  }

  .textContent .text {
    font-size: var(--font-xsmall); /* Adjust text size */
    text-align: center; /* Center the text */
  }

  .title {
    font-size: var(--font-large); /* Adjust title size for mobile */
    margin: 20px 0;
  }
}
