/* Biryani Font-Faces */
@font-face {
  font-family: "Biryani";
  src: local("Biryani Black"),
    url("../../assets/font/Biryani/Biryani-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Biryani";
  src: local("Biryani Bold"),
    url("../../assets/font/Biryani/Biryani-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Biryani";
  src: local("Biryani ExtraBold"),
    url("../../assets/font/Biryani/Biryani-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Biryani";
  src: local("Biryani ExtraLight"),
    url("../../assets/font/Biryani/Biryani-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Biryani";
  src: local("Biryani Light"),
    url("../../assets/font/Biryani/Biryani-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Biryani";
  src: local("Biryani Regular"),
    url("../../assets/font/Biryani/Biryani-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Biryani";
  src: local("Biryani SemiBold"),
    url("../../assets/font/Biryani/Biryani-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* Fjalla One Font-Face */
@font-face {
  font-family: "Fjalla One";
  src: local("FjallaOne Regular"),
    url("../../assets/font/Fjalla_One/FjallaOne-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Noto Serif Display Font-Faces */
@font-face {
  font-family: "Noto Serif Display";
  src: local("NotoSerifDisplay Italic"),
    url("../../assets/font/Noto_Serif_Display/NotoSerifDisplay-Italic-VariableFont_wdth\,wght.ttf")
      format("truetype");
  font-weight: normal; /* Variable fonts can support multiple weights, but I've used 'normal' as a placeholder */
  font-style: italic;
}

@font-face {
  font-family: "Noto Serif Display";
  src: local("NotoSerifDisplay Regular"),
    url("../../assets/font/Noto_Serif_Display/NotoSerifDisplay-VariableFont_wdth,wght.ttf")
      format("truetype");
  font-weight: normal; /* Variable fonts can support multiple weights */
  font-style: normal;
}

/* Source Sans 3 Font-Faces */
@font-face {
  font-family: "Source Sans 3";
  src: local("SourceSans3 Italic"),
    url("../../assets/font/Source_Sans_3/SourceSans3-Italic-VariableFont_wght.ttf")
      format("truetype");
  font-weight: normal; /* Variable fonts can support multiple weights */
  font-style: italic;
}

@font-face {
  font-family: "Source Sans 3";
  src: local("SourceSans3 Regular"),
    url("../../assets/font/Source_Sans_3/SourceSans3-VariableFont_wght.ttf")
      format("truetype");
  font-weight: normal; /* Variable fonts can support multiple weights */
  font-style: normal;
}

/* Tenor Sans Font-Face */
@font-face {
  font-family: "Tenor Sans";
  src: local("TenorSans Regular"),
    url("../../assets/font/Tenor_Sans/TenorSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Voga Font-Face */
@font-face {
  font-family: "Voga";
  src: local("Voga Medium"),
    url("../../assets/font/Voga-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

/*Libre Baskerville*/
@font-face {
  font-family: "Libre Baskerville";
  src: local("LibreBaskerville Regular"),
    url("../../assets/font/Libre_Baskerville/LibreBaskerville-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Pacifico Font-Face */
@font-face {
  font-family: "Pacifico";
  src: local("Pacifico Regular"),
    url("../../assets/font/Pacifico/Pacifico-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/*global style*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* Breakpoints */
  --mobile-breakpoint: 480px;
  --tablet-portrait-breakpoint: 768px;
  --tablet-landscape-breakpoint: 1024px;

  /*website*/
  --padding: 50px;
  --padding-mobile: 15px;
  --padding-tablet: 30px;

  /* Colors */
  --logo-color: #000080;
  --logo-color-2: #7474b4;
  --background-color-white: #fff;
  --background-color-black: #010101;
  --text-color: #000;
  --text-color-2: #fff;

  /* Font size defined */
  --font-tiny: 6px;
  --font-small: 10px; /* 12px - Used for secondary text elements, like captions. */
  --font-regular: 12px; /* 16px - Standard text size for body content. */
  --font-medium: 16px; /* 20px - Maybe used for smaller headings or highlighted content. */
  --font-large: 24px; /* 24px - Common for primary headings. */
  --font-xlarge: 32px; /* 32px - Used for prominent headers, possibly on hero banners. */
  --font-xxlarge: 48px; /* 48px - Very large size for major banners or promotional areas. */
  --font-giant: 64px; /* 64px - Exceptionally large size, used sparingly for major emphasis. */
}
.container {
  position: relative;
}
