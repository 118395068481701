.searchInput {
  height: 60px;
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  max-width: 400px;
  font-size: var(--font-medium);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
  .searchInput {
    height: 35px;
    margin-bottom: 10px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    max-width: 200px;
    font-size: var(--font-regular);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
}
