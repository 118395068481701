.serviceSection {
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: aliceblue;
}

.container {
  width: 100%;
  max-width: 1200px; /* Set max-width to 1200px */
  padding: 0 20px;
  margin: 0 auto;
}

.title,
.subTitle {
  font-size: var(--font-xxlarge);
  text-align: center;
  margin-bottom: 30px;
}

.title {
  font-family: "Fjalla One";
}

.subTitle {
  font-family: "Biryani";
  font-weight: 600;
}

.imageContainer {
  width: 100%;
  height: 200px; /* Fixed height for all images */
  overflow: hidden; /* Ensure any overflow is hidden */
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer img {
  width: 100%;
  height: 100%; /* Enforce fixed height */
  object-fit: cover; /* Make sure images cover the area without stretching */
}

.serviceGallery {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.serviceColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  max-width: 350px; /* Ensure each column doesn't exceed 350px */
}

.description {
  font-size: calc(var(--font-medium));
  text-align: center;
  margin-top: 10px;
}

.viewMoreBtn {
  margin: 20px 0;
  text-align: end;
}

.viewMoreBtn a {
  display: inline-block;
  padding: 10px 25px;
  text-decoration: none;
  color: var(--text-color);
  font-size: var(--font-medium);
  font-weight: bold;
  transition: all 0.5s ease-in-out;
  border: 1px solid black;
  border-radius: 50px;
}

.viewMoreBtn a:hover {
  transform: scale(1.1);
}

/* Tablet - Landscape */
@media screen and (max-width: 1024px) {
  .serviceGallery {
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }
  .serviceColumn {
    width: 80%;
  }
}

/* Tablet - Portrait */
@media screen and (max-width: 768px) {
  .serviceSection {
    padding: 0 var(--padding-mobile);
  }
  .title {
    font-size: var(--font-xlarge);
  }
  .subTitle {
    font-size: var(--font-large);
  }
  .serviceGallery {
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }
  .serviceColumn {
    width: 100%;
    max-width: 100%;
  }
}

/* Mobile */
@media screen and (max-width: 480px) {
  .serviceSection {
    padding: 0 var(--padding-mobile);
  }
  .serviceGallery {
    gap: 20px;
  }
  .serviceColumn {
    width: 100%;
    max-width: 100%;
  }
}
