.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.slides {
  width: 100%;
  height: 100%;
  padding-top: 100%;
  object-fit: cover;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 2s;
}

.active {
  opacity: 1;
}

.slide img {
  width: 100%;
  height: 100%;
}

.rightButton,
.leftButton {
  position: absolute;
  height: 10%;
  width: 10%;
  background-color: #333;
  opacity: 0.8;
  cursor: pointer;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 25px;
}

.leftButton {
  left: 15px;
}

.rightButton {
  right: 15px;
}

.leftButton::after,
.rightButton::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 50%;
  border: solid white;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 3px;
  width: 5%;
  height: 5%;
}

.leftButton::after {
  transform: translate(75%, -50%) rotate(135deg);
}

.rightButton::after {
  transform: translate(25%, -50%) rotate(-45deg);
}
.imageChangeButtonsContainer {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 5px 10px;
  border-radius: 15px;
}

.imageChangeButtons {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px #fff solid;
  opacity: 1;
  margin: 0 5px 0 5px;
  cursor: pointer;
}

.imageChangeButtons.active {
  background-color: #fff;
  border: 1px #333 solid;
}

@media screen and (max-width: 640px) {
  .imageChangeButtonsContainer {
    display: none;
  }
}
