.container {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  width: 1200px;
}
.mobileDisplay,
.desktopDisplay {
  display: flex;
  justify-content: center;
}

.mobileDisplay {
  display: none;
}
@media (max-width: 768px) {
  .container {
    width: 300px;
    margin: 0auto;
  }
  .mobileDisplay {
    display: flex;
  }
  .desktopDisplay {
    display: none;
  }
}
