.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
}

.slides {
  width: 100%;
  height: 100%;
  position: relative;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 2s;
}

.active {
  opacity: 1;
}
.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rightButton,
.leftButton {
  position: absolute;
  height: 10%;
  width: 10%;
  background-color: white;
  opacity: 0.8;
  cursor: pointer;
  bottom: 50%;
  transform: translateY(50%);
  border-radius: 25px;
}

.leftButton {
  left: 15px;
}

.rightButton {
  right: 15px;
}

.leftButton::after,
.rightButton::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 50%;
  border: solid black;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 3px;
  width: 5%;
  height: 5%;
}

.leftButton::after {
  transform: translate(75%, -50%) rotate(135deg);
}

.rightButton::after {
  transform: translate(25%, -50%) rotate(-45deg);
}
@media (max-width: 768px) {
  .slides {
    width: 100%;
    height: 250px;
    position: relative;
    border-radius: 50px 50px 0 0;
    overflow: hidden;
  }
  .leftButton {
    left: 15px;
  }

  .rightButton {
    right: 15px;
  }
}
