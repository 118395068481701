.jobContainer {
  position: relative;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  overflow: hidden;
}

.innerContainer {
  width: 100%;
  max-width: 1400px; /* Limit the maximum width to 1200px */
}

.title {
  width: 100%;
  max-width: 100%;
  border-top: 1px solid var(--primary-color, #333);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
}

.title > div {
  font-size: var(--font-large, 32px);
  font-family: "Noto Serif Display";
  margin: 5px 0;
  color: #262728;
  font-weight: lighter;
}

.job-container {
  background-color: var(--background-color, #fff);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Tablet - Landscape */
@media screen and (max-width: 1024px) {
  .innerContainer {
    padding: 0 15px;
  }
}

/* Tablet - Portrait */
@media screen and (max-width: 768px) {
  .jobContainer {
    margin: 20px 0;
  }
  .innerContainer {
    padding: 0 10px;
  }
}

/* Mobile */
@media screen and (max-width: 480px) {
  .innerContainer {
    padding: 0 5px;
  }
}
